import { Box, Flex } from "@chakra-ui/react";
import { HeaderNav } from "./HeaderNav";

export function Header() {
  return (
    <Flex
      as="header"
      w="100%"
      h="80px"
      bg="purple.900"
      justify="center"
      align="center"
    >
      <Flex
        w="100%"
        justify="center"
        align="center"
        maxW={1440}
      >
        <Box mx="auto">
          <HeaderNav />
        </Box>
      </Flex>
    </Flex>
  )
}