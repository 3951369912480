import { Route, Routes as RoutesDOM } from "react-router-dom"

import { SimulationRoi } from "../pages/SimulationRoi";
import { SimulationStock } from "../pages/SimulationStock";

export const Routes = () => (
  <RoutesDOM>
    <Route path="/" element={<SimulationRoi />} />
    <Route path="/stock" element={<SimulationStock />} />
  </RoutesDOM>
)