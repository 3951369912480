import { ChakraProvider } from "@chakra-ui/react";
import { BrowserRouter } from 'react-router-dom';

import { Header } from "./components/Header";
import { Routes } from "./routes";

import { theme } from "./styles/theme";

export const App = () => (
  <BrowserRouter>
    <ChakraProvider theme={theme}>
      <Header />
      <Routes />
    </ChakraProvider>
  </BrowserRouter>
)
