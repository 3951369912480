import { Stack } from "@chakra-ui/react";

import { NavLink } from "./NavLink";

export function HeaderNav() {
  return (
    <Stack spacing="6" direction="row">
      <NavLink href="/">Simulação ROI</NavLink>
      <NavLink href="/stock">Simulação Estoque</NavLink>
    </Stack>
  )
}