/* eslint-disable react/no-children-prop */
import { 
  Input as ChakraInput, 
  FormLabel, 
  FormControl,
  InputProps as ChakraInputProps,
  FormErrorMessage,
  InputGroup,
  InputRightElement,
  InputLeftElement
} from "@chakra-ui/react";
import { forwardRef, ForwardRefRenderFunction } from "react";
import { FieldError } from "react-hook-form";

interface InputProps extends ChakraInputProps {
  name: string;
  label?: string;
  error?: FieldError;
  disabled?: boolean;
  input_elementl?: string;
  input_elementr?: string;
}

const InputBase: ForwardRefRenderFunction<HTMLInputElement, InputProps> 
  = ({ name, label, error = null, disabled = false, input_elementl, input_elementr, ...rest }, ref) => {
    return (
      <FormControl isInvalid={!!error}>
        { !!label && <FormLabel htmlFor={name}>{label}</FormLabel>}

        <InputGroup>
          {input_elementl && (
            <InputLeftElement 
              top="4px"
              left="5px"
              fontSize="1.2em"
              children={input_elementl}
            />
          )}
          <ChakraInput
            id={name}
            name={name} 
            borderColor={error ? "red.600" : "gray.600"}
            focusBorderColor={error ? "red.600" : "purple.900"}
            variant="filled"
            bgColor="white"
            disabled={disabled}
            borderRadius="10"
            _hover={{
              borderColor: "purple.900",
            }}
            _disabled={{
              bgColor: "gray.200",
            }}
            size="lg"
            ref={ref}
            {...rest}
          />
          {input_elementr && (
            <InputRightElement 
              top="4px"
              right="5px"
              fontSize="1.3em"
              fontWeight="bold"
              children={input_elementr}
            />
          )}
        </InputGroup>

        { !!error && (
          <FormErrorMessage>
            {error.message}
          </FormErrorMessage>
        )}
      </FormControl>
    )
  }

export const Input = forwardRef(InputBase);