import { useMemo, useState } from 'react';
import { Box, Button, Flex, Heading, Stack, Text, VStack } from '@chakra-ui/react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

import { Input } from '../components/Form/Input';

import getCaculatePercentage from '../utils/getCaculatePercentage';
import api from '../services/api';

interface DataFormSimulation {
  patient_month: string;
  dry_eye_patient: string;
  dry_eye_patient_porc: string;
  patient_requires_treatment: string;
  patient_requires_treatment_porc: string;
  result_patient_month: string;
  doctor_fee: string;
  product_cost: string;
  treatment_cost: string;
  product_stock: string;
  month_stock: string;
  incremental_gain_month: string;
  incremental_gain_yeah: string;
}

interface ResponseStock {
  data: string;
}

const createSimulationFormSchema = yup.object().shape({
  patient_month: yup.string().required("Pacientes Mensais Obrigatório"),
  dry_eye_patient_porc: yup.string().required("(%) Pacientes com olho seco Obrigatório"),
  patient_requires_treatment_porc: yup.string().required("(%) Pacientes que requerem tratamento Obrigatório"),
  doctor_fee: yup.string().required("Valor Tratamento Obrigatório"),
  product_cost: yup.string().required("Custo Unitário do Smart Tip Obrigatório"),
});

export function SimulationStock() {
  const [patientMonth, setPatientMonth] = useState("");
  const [dryEyePorc, setDryEyePorc] = useState("");
  const [dryEye, setDryEye] = useState("");
  const [requiresTreatmentPorc, setRequiresTreatmentPorc] = useState("");
  const [requiresTreatment, setRequiresTreatment] = useState("");
  const [doctorFee, setDoctorFee] = useState("");
  const [productCost, setProductCost] = useState("0");
  const [tratmentCost, setTratmentCost] = useState("0");
  const [productStock, setProductStock] = useState("0");

  const { register, handleSubmit, formState, setValue } = useForm<DataFormSimulation>({
    resolver: yupResolver(createSimulationFormSchema)
  });

  const { errors, isSubmitting } = formState;

  useMemo(() => {
    if(parseInt(dryEyePorc) > 0 && parseInt(dryEyePorc) <= 100) {
      const result = getCaculatePercentage(
        parseInt(dryEyePorc), 
        parseInt(patientMonth)
      );

      setValue("dry_eye_patient", result.toString());
      setDryEye(result.toString());
    } else {
      setValue("dry_eye_patient", "0");
      setDryEye("0");
    }
  }, [patientMonth, dryEyePorc, setValue]);

  useMemo(() => {
    if(parseInt(requiresTreatmentPorc) > 0 && parseInt(requiresTreatmentPorc) <= 100) {
      const result = getCaculatePercentage(
        parseInt(requiresTreatmentPorc), 
        parseInt(dryEye)
      );

      setValue("patient_requires_treatment", result.toString());
      setValue("result_patient_month", result === 1 ? `${result.toString()} paciente mensal` : `${result.toString()} pacientes mensais`);
      setRequiresTreatment(result.toString());
    } else {
      setValue("patient_requires_treatment", "0");
      setValue("result_patient_month", "0 paciente mensal");
      setRequiresTreatment("0");
    }
  }, [dryEye, requiresTreatmentPorc, setValue]);

  useMemo(() => {
    if(parseFloat(doctorFee.replace(".", "").replace(",", ".")) > 0 && parseFloat(productCost.replace(".", "").replace(",", ".")) > 0) {
      !productCost && setProductCost("0");
      const result_tratment = parseFloat(doctorFee.replace(".", "").replace(",", ".")) - parseFloat(productCost.replace(".", "").replace(",", "."));
  
      const resultFormat = new Intl.NumberFormat('pt-BR', { 
        style: 'currency', currency: 'BRL' 
      }).format(result_tratment);

      setTratmentCost(result_tratment.toFixed(2).toString().replace(".", ","));
      setValue("treatment_cost", resultFormat);
    } else {
      const resultFormat = new Intl.NumberFormat('pt-BR', { 
        style: 'currency', currency: 'BRL' 
      }).format(0);

      setTratmentCost("0,00");
      setValue("treatment_cost", resultFormat);
    }
  }, [doctorFee, productCost, setValue]);

  useMemo(() => {
    const maxSmartsBuy = parseInt(requiresTreatment) * 12;

    if(parseInt(productStock) <= maxSmartsBuy && parseInt(productStock) > 0) {      
      const result_month_stock = parseInt(productStock) / parseInt(requiresTreatment) ;
      
      setValue("month_stock", Math.floor(result_month_stock).toString());
    }  else {
      setValue("month_stock", "0");
    }
  }, [productStock, requiresTreatment, setValue]);  

  useMemo(() => {
    const month_yeah = 12;

    if(
      parseFloat(tratmentCost.replace(".", "").replace(",", ".")) > 0 && 
      parseInt(requiresTreatment) > 0
    ) {
      const result_month = parseFloat(tratmentCost.replace(".", "").replace(",", "."))  * parseInt(requiresTreatment);

      const resultFormatMonth = new Intl.NumberFormat('pt-BR', { 
        style: 'currency', currency: 'BRL' 
      }).format(result_month);
      
      const resultFormatYeah = new Intl.NumberFormat('pt-BR', { 
        style: 'currency', currency: 'BRL' 
      }).format(result_month * month_yeah);

      setValue("incremental_gain_month", resultFormatMonth);
      setValue("incremental_gain_yeah", resultFormatYeah);
    } else {
      const resultFormatMonth = new Intl.NumberFormat('pt-BR', { 
        style: 'currency', currency: 'BRL' 
      }).format(0);
      
      const resultFormatYeah = new Intl.NumberFormat('pt-BR', { 
        style: 'currency', currency: 'BRL' 
      }).format(0);

      setValue("incremental_gain_month", resultFormatMonth);
      setValue("incremental_gain_yeah", resultFormatYeah);
    }
  }, [tratmentCost, requiresTreatment, setValue]);

  const handleGeneretionPdf: SubmitHandler<DataFormSimulation> = async (data) => {
    const stock: ResponseStock = await api.post("simulations/stock/report/", {
      doctor_fee: data.doctor_fee,
      dry_eye_patient: data.dry_eye_patient,
      patient_month: data.patient_month,
      patient_requires_treatment: data.patient_requires_treatment,
      product_cost: data.product_cost,
      result_patient_month: data.result_patient_month,
      treatment_cost: data.treatment_cost,
      incremental_gain_month: data.incremental_gain_month,
      incremental_gain_yeah: data.incremental_gain_yeah,
      product_stock: data.product_stock,
      month_stock: data.month_stock,
    });

    window.open(`${process.env.NODE_ENV === 'production' ? process.env.REACT_APP_URL_API_PRODUCTION : process.env.REACT_APP_URL_API_DEVELOPMENT}${stock.data}`, "_blank")
  }

  return (
    <Flex w="100%" justify="center" align="center">
      <Flex w="100%" maxW={1280} my="6" justify="center" align="center" px="1">
        <Box
          as="form"
          flex="1"
          borderRadius={8}
          p={["6", "8"]}
          onSubmit={handleSubmit(handleGeneretionPdf)}
        >
          <Heading
            size="lg"
            color="purple.900"
            fontWeight="bold"
            textTransform="uppercase"
          >
            Simulação Estoque
          </Heading>
      
          <Stack spacing={["3", "6"]} direction={["column", "row"]} my="6">
            <VStack spacing="3" w="100%">
              <Input
                label="Pacientes Mensais:"
                type="number"
                min="1"
                error={errors.patient_month}
                {...register("patient_month")}
                onChange={(e) => {setPatientMonth(e.currentTarget.value)}}
              />
      
              <Box w="100%">
                <Text fontWeight="medium" mb="3">Pacientes com olho seco (%):</Text>
                <Stack spacing={["3", "6"]} direction={["column", "row"]}>
                  <Input
                    type="number"
                    min="1"
                    max="100"
                    input_elementr="%"
                    error={errors.dry_eye_patient_porc}
                    {...register("dry_eye_patient_porc")}
                    onChange={(e) => {setDryEyePorc(e.currentTarget.value)}}
                  />
                  <Input
                    disabled={true}
                    {...register("dry_eye_patient")}
                  />
                </Stack>
              </Box>

              <Box w="100%">
                <Text fontWeight="medium" mb="3">Pacientes que requerem tratamento (%):</Text>
                <Stack spacing={["3", "6"]} direction={["column", "row"]}>
                  <Input
                    type="number"
                    min="1"
                    max="100"
                    input_elementr="%"
                    error={errors.patient_requires_treatment_porc}
                    {...register("patient_requires_treatment_porc")}
                    onChange={(e) => {setRequiresTreatmentPorc(e.currentTarget.value)}}
                  />
                  <Input
                    disabled={true}
                    {...register("patient_requires_treatment")}
                  />
                </Stack>
              </Box>
      
              <Input
                mt="3"
                disabled={true}
                _disabled={{
                  color: "white",
                  bgColor: "purple.900",
                }}
                {...register("result_patient_month")}
              />
              
              <Stack spacing={["3", "6"]} direction={["column", "row"]} w="100%">
                <Input
                  label="Valor Tratamento:"
                  input_elementl="R$"
                  error={errors.doctor_fee}
                  {...register("doctor_fee")}
                  onChange={(e) => {setDoctorFee(e.currentTarget.value)}}
                />
                
                <Input
                  label="Valor Unit. Smart Tip:"
                  input_elementl="R$"
                  error={errors.product_cost}
                  {...register("product_cost")}
                  onChange={(e) => {setProductCost(e.currentTarget.value)}}
                />
              </Stack>

              <Input
                label="Ganho Final Tratamento:"
                disabled={true}
                {...register("treatment_cost")}
              />
            </VStack>
      
            <VStack spacing="3" w="100%">
              <Input
                label="Smarts Tips Comprados:"
                type="number"
                {...register("product_stock")}
                onChange={(e) => {setProductStock(e.currentTarget.value)}}
              />

              <Input
                label="Meses de estoque:"
                disabled={true}
                _disabled={{
                  color: "white",
                  bgColor: "purple.900",
                }}
                {...register("month_stock")}
              />
      
              <Stack spacing={["3", "6"]} direction={["column", "row"]} w="100%">
                <Input
                  label="Ganho Incremental Mês:"
                  disabled={true}
                  _disabled={{
                    color: "white",
                    bgColor: "purple.900",
                  }}
                  {...register("incremental_gain_month")}
                />
                <Input
                  label="Ganho Incremental Ano:"
                  disabled={true}
                  _disabled={{
                    color: "white",
                    bgColor: "purple.900",
                  }}
                  {...register("incremental_gain_yeah")}
                />
              </Stack>
            </VStack>
          </Stack>
      
          <Flex mt="8">
            <Button
              type="submit"
              bgColor="purple.900"
              color="white"
              fontSize="1.5em"
              borderRadius="10"
              _hover={{
                filter: "brightness(0.9)",
              }}
              isLoading={isSubmitting}
              w={["100%", "100%", "100%", "400px"]}
              h="60px"
            >
              Gerar PDF
            </Button>
          </Flex>
        </Box>
      </Flex>
    </Flex>
  )
}